.form {
    width: 400px;
    margin: auto;
    padding: 40px;
}

.document {
    max-width: 700px;
    margin: auto;
    padding: 40px;
}

.signButton {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
}