html, body, #root, .App {
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
 
::-webkit-scrollbar-thumb {
  background: #666 ;
}

::-webkit-scrollbar-track {
  background: #ddd ;
} */

.Sidebar {
  font-size: 14px;
  padding:20px;
  color: black;
  background: #f3f3f3;  
  flex-shrink: 0;
  width: 250px;
  height: 100%;
  overflow: auto;
}

.MenuItem {
  cursor: pointer;
  margin:8px;
  padding: 5px 0px;
  color:black;
}

.MenuItem a {
  color: #6D6D6D;
}

.Codebar {
  font-size: 12px;
  background: #2d2d2d;
  height: 100%;
  width: 650px;
  flex-shrink: 0;
  white-space: pre-wrap;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.CodebarContent {
  
}

.CodebarSectionTitle {
  color: #ccc;
  font-weight: bold;
}

.CodebarSection {
  border: 1px solid #7d7d7d;
  border-radius: 5px;
}

.CodebarContent::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

.CodebarContent::-webkit-scrollbar-track {
  background: transparent;        /* color of the tracking area */
}

.CodebarContent::-webkit-scrollbar-thumb {
  background-color: #151515;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

.MainContent {
  background: white;  
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  color:#525767;
  line-height: 25px;
  font-size: 14px;
}

.MainContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.Content {
  padding:30px 80px
}

.Header {
  color:black;
  padding:30px;
  background: white;
  border-bottom:1px solid #e0dfdf;
  margin-bottom:20px;
}


p {
  padding:0px 20px;
}

.BlueSection {
  padding:20px;
  background: #c6e6ff;
  margin-bottom:20px;
}

.Subtitle {
  padding:20px;
  border-bottom:1px solid #e0dfdf;
  margin-bottom:20px;
}

.CodeLangSelect, .CodeLangSelect:focus {
  background: #272822;
  color: white;
  border: none;
}

.margin {
  margin: 10px;
}

pre[class*=language-] {
  flex-grow: 1;
}

